import {setCookie} from '@/common'
import {t} from '@lingui/macro'

export const API_URL = process.env.NEXT_PUBLIC_API_URL
export const isMockedApi = process.env.IS_MOCKED_API === `true`

const authCheck = (res: Response) => {
  if ((res.status === 401 || res.status === 403) && !res.url.includes(`login`)) {
    setCookie(`jwt`, ``, 0)
    setCookie(`authorized`, `0`)
    window.location.href = `/login/`
    return {errors: {system: t({id: `Unauthorized`})}}
  }
  return res.json()
}

export const fetchData = <T extends Record<string, string>>(uri: string, params?: T) => {
  const query = new URLSearchParams(params)
  const url = params ? `${uri}?${query.toString()}` : uri

  return fetch(url, {
    headers: {'Content-Type': `application/json`},
    method: `GET`,
  }).then(authCheck)
}

export const postData = <T>(url: string, payload: T) =>
  fetch(url, {
    headers: {'Content-Type': `application/json`},
    method: `POST`,
    body: JSON.stringify(payload),
  }).then(authCheck)

export const putData = <T>(url: string, payload: T) =>
  fetch(url, {
    headers: {'Content-Type': `application/json`},
    method: `PUT`,
    body: JSON.stringify(payload),
  }).then(res => res.json())

export const deleteData = <T extends Record<string, string>>(uri: string, params?: T) => {
  const query = new URLSearchParams(params)
  const url = params ? `${uri}/?${query.toString()}` : uri

  return fetch(url, {
    headers: {'Content-Type': `application/json`},
    method: `DELETE`,
  }).then(authCheck)
}

/**
 * Server side api request handler
 * in NextJs api we add extra payload in the headers (jwt, locale)
 * in client side we don't have access to jwt cookie
 * in client side we accept json -> {data, errors}, we dont care about response status
 * all statuses we check in the handler and convert to data or errors object
 * I feel smell of GraphQL, don't I?
 */
export const apiHandler = async (request, response) => {
  const uri = request.url.replace(`/api`, ``)
  const headers = {
    'Accept-Language': request.cookies?.NEXT_LOCALE,
    'Authorization': `Bearer ${request.cookies?.jwt}`,
    'Content-Type': `application/json`,
  }
  const options = {
    headers,
    method: request.method,
    body: Object.keys(request.body).length
      ? JSON.stringify(request.body)
      : undefined,
  }

  try {
    let url = cleanUrlForBackend(`${API_URL}${uri}`)
    let apiResponse = await fetch(url, options)
    response.status(apiResponse.status)

    if (apiResponse.ok) {
      const text = await apiResponse.text()
      const data = text ? JSON.parse(text) : {}
      return response.json({data})
    }
    const errors = await apiResponse.json()
    response.json({errors})
  }
  catch (error) {
    response.json({errors: {system: error}})
  }
}

const cleanUrlForBackend = (url:string) => {
  url = url.replace(/\/$/, ``)
  // Remove slash before a question mark
  url = url.replace(/\/(?=\?)/g, ``)
  return url
}
