/* eslint-disable @typescript-eslint/no-explicit-any */

type FunctionLike = (...args: any[]) => any

export const isClient = (): boolean => typeof window !== `undefined`

export const isFunction = (value: any): value is FunctionLike => typeof value === `function`

export const isObject = (value: any): value is object => typeof value === `object`

export const isString = (value: any): value is string => typeof value === `string`

export const isNumber = (value: any): value is number => typeof value === `number`

export const {isArray} = Array
