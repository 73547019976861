import type {CSSProperties} from 'react'
import {isArray, isObject} from './is-type'

export * from './is-type'

export const toKebabCase = (str: string): string => (
  str.replace(/[A-Z_]/g, letter => `-${letter.toLowerCase()}`)
)

type Obj = Record<string, unknown>

/**
 * Function deep merge two objects
 * e.g: mergeDeep({a: 1, b: 2})({b: 3, c: 4}) -> {a: 1, b: 3, c: 4}
 *
 * @param obj => overrideObject
 * @returns obj | overrideObject
 */
export const mergeDeep = <T extends Obj>(obj: Partial<T>) => (override: Partial<T>): Partial<T> => (
  Object
    .entries(override)
    .reduce((acc, [key, extend] : [keyof T, T[keyof T]]) => {
      const value = obj[key]
      if (isArray(value) && isArray(extend)) {
        acc[key] = [...value, ...extend] as T[keyof T]
        return acc
      }
      if (isObject(value) && isObject(extend)) {
        acc[key] = mergeDeep(value as Partial<T>)(extend) as T[keyof T]
        return acc
      }
      acc[key] = extend
      return acc
    }, {...obj})
)

export const cssVars = (obj: Record<string, unknown>, unit = ``) => Object
  .entries(obj)
  .reduce((acc, [key, value]) => {
    if (value !== undefined) {
      acc[`--${toKebabCase(key)}`] = unit ? `${value}${unit}` : value
    }
    return acc
  }, {} as Record<string, unknown>) as CSSProperties
