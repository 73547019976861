import React, {useEffect} from 'react'
import * as yup from 'yup'
import Link from 'next/link'
import css from './styles.module.scss'
import {Button, Card, FormField, Input, Checkbox, H3, InfoMessage} from '@doctena-org/ui-components/src'
import {setErrors} from '@/common'
import {useAuth, LoginForm} from '@/store'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {useLingui} from '@lingui/react'
import {t} from '@lingui/macro'
import {Turnstile, TurnstileInstance} from '@marsidev/react-turnstile'

const getValidationSchema = (i18n) => yup.object().shape({
  username: yup
    .string()
    .required(i18n._(`Required field`))
    .matches(new RegExp(`^[\\w-\\.+]+@([\\w-]+\\.)+[\\w-]{2,4}$`), i18n._(`Invalid email address`)),
  password: yup
    .string()
    .required(i18n._(`Required field`)),
})

const Login = () => {
  const {i18n} = useLingui()
  const captchaSiteKey = process.env.CAPTCHA_SITE_KEY || ``
  const [captchaErrors, setCaptchaErrors] = React.useState({
    message: undefined,
  })
  const formRef = React.useRef<TurnstileInstance>(null)
  const schema = getValidationSchema(i18n).required()
  const {errors: apiErrors, isLoading, login, set} = useAuth()
  const {formState: {errors}, handleSubmit, register, setError, watch} = useForm<LoginForm>({
    resolver: yupResolver(schema),
  })
  const isSubmitDisabled = !watch(`username`)?.length || !watch(`password`)?.length
  useEffect(() => () => {
    setTimeout(() => set({'errors': null}), 10) // wired errors, `setErrors` looks like overlapping
  }, [])

  setErrors(apiErrors, setError)

  async function onSubmit(formData: LoginForm) {
    const token = formRef.current?.getResponse() ?? false
    await fetch(`/api/verify-captcha-token`,
      {
        headers: {'content-type': `application/json`},
        method: `POST`,
        body: JSON.stringify({token}),
      }).then((data) => {
      // reset token after verification
      formRef.current?.reset()
      if (!data.ok) {
        // Captcha Challenge Fail - Show error message
        return onErrorCaptchaToken()
      }
      // Captcha Challenge Success - Continue with login
      login(formData, () => {
        formRef.current?.remove()
      })
    })
  }

  /**
   * Show error message when captcha token is invalid
   */
  function onErrorCaptchaToken() {
    // @ts-ignore
    setCaptchaErrors({message: `Something went wrong. Please refresh the page and log in again.`})
  }

  return (
    <main className={css.main}>
      <Card className={css.card}>
        <H3 className={css.title}>
          {t({id: `Login for practitioners`})}
        </H3>
        <hr/>
        <form
          data-testid='login-form'
          autoComplete='off'
          className={css.form}
          onSubmit={handleSubmit(onSubmit)}>
          <FormField
            label={i18n._(`Email`)}
            error={errors?.username?.message}>
            <Input
              testId='username'
              autoComplete='new-password'
              icon='email'
              placeholder={i18n._(`Email`)}
              {...register(`username`)}

            />
          </FormField>
          <FormField
            label={i18n._(`Password`)}>
            <Input
              testId='password'
              autoComplete='new-password'
              icon='password'
              type='password'
              {...register(`password`)}
            />
          </FormField>
          <Checkbox
            testId='remember-me'
            className='justify-self-start text-[12px] !p-[15px] !rounded-[4px]'
            {...register(`rememberMe`)}>
            {i18n._(`Keep logged in`)}
          </Checkbox>
          <Link
            href='/password/recovery/'
            className={css.link}
            data-testid='recover-password'>
            {i18n._(`Forgot your password?`)}
          </Link>
          <div className={css.oneRow}>
            <InfoMessage
              data-testid='form-info-message'
              type='error'
              className={css.infoMessage}
              isVisible={!!(apiErrors?.message) || !!(captchaErrors?.message)}
              text={apiErrors?.message || captchaErrors?.message}
            />
            <Button
              data-testid='button-submit'
              color='tertiary'
              disabled={isLoading || isSubmitDisabled}
              icon={isLoading ? `spinner` : `arrow-down`}
              iconPosition='right'
              iconRotation={isLoading ? Infinity : 270}
              type='submit'>
              {i18n._(`Continue`)}
            </Button>
          </div>
        </form>
      </Card>
      <Turnstile
        ref={formRef}
        siteKey={captchaSiteKey}
        options={{size: `invisible`}}
        onError={() => onErrorCaptchaToken()}
      />
    </main>
  )
}

export default Login
