import {createContext, useContext} from 'react'
import {toKebabCase} from '../utils'

export type UiContextType = {
  prefix: string,
  spriteUrls: Record<string, string>
}

const defaultValue: UiContextType = {
  prefix: `ui`,
  spriteUrls: {
    default: `icon-sprites.svg`,
    flag: `flag-sprites.svg`,
  }}

export const UiContext = createContext<UiContextType>(defaultValue)
export const UiProvider = UiContext.Provider

type CssParams =
  | string
  | Record<string, boolean | string>
  | Record<`skipPrefix`, string | undefined>

export type UseUi = (base: string) => {
  css: (params: CssParams) => string
  spriteUrls: UiContextType['spriteUrls']
}

/*
  Utility function for building css class names
  it adds global & component level prefixes,
  the global one is passed by UiProvider and by default it equals to `ui`
  the component prefix is passed by useUI hook e.g:
    const {css} = useUI(`button`)
    css(`primary`) -> will produce `ui-button-primary` string

  it can accept conditional input e.g:
    css({isDisabled: true}) -> will produce `ui-button-is-disabled` string
    note 👆, it converts property name from camelCase to kebab-case
    the value should be a boolean value: true / false

  if you pass object with string value, it will be treated as a class name, e.g:
    const color = `red`
    css({color}) -> will produce `ui-button-red` string

  there is a case when you need to skip prefixing, for example for tailwind classes, e.g:
    css({skipPrefix: `text-gray-700`}) -> will produce `text-gray-700` string.

  Be careful with CSS, it may hurt your mind!
*/

export const useUI: UseUi = component => {
  const {prefix, spriteUrls} = useContext(UiContext)
  return {
    spriteUrls,
    css: params => typeof params === `string`
      ? [prefix, component, params].filter(Boolean).join(`-`)
      : Object
        .entries(params)
        .reduce((acc, [key, value]) => {
          if (key === `skipPrefix`) return value ? `${acc} ${value}` : acc
          const property = value === true ? toKebabCase(key) : value
          if (component === property) return `${acc} ${prefix}-${component}`
          const className = [prefix, component, property].filter(Boolean).join(`-`)
          return property && className ? acc.concat(` ${className}`) : acc
        }, ``)
        .trim(),
  }
}
